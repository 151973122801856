import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

type BlogEntry = {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    title: string
    description: string | null
    coverImage: any
    categories: Array<string>
  }
}

export default function BlogEntry(props: { post: BlogEntry }) {
  const categoryLabel =
    props.post.frontmatter.categories[0]?.replace(/-/g, " ") ?? ""
  const image = getImage(props.post.frontmatter.coverImage)
  return (
    <Link
      to={props.post.fields.slug}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-shrink-0">
        {image && (
          <GatsbyImage
            className="h-48 w-full object-cover"
            image={image}
            alt={props.post.frontmatter.title}
          />
        )}
      </div>
      <div className="flex-1 pt-4 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm text-blue uppercase font-bold tracking-widest">
            {categoryLabel}
          </p>
          <p className="text-xl font-semibold text-gray-900">
            {props.post.frontmatter.title}
          </p>
          <p className="mt-3 text-base text-gray-500">{props.post.excerpt}</p>
        </div>
        <div className="mt-6 text-sm text-gray-500">
          {props.post.frontmatter.date}
        </div>
      </div>
    </Link>
  )
}
