import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrimaryButton } from "../components/button"
import { Routes } from "../data/routes"
import { ClosingCTA } from "../components/CTA"
import Layout from "../components/layout"
import Popup from "../components/popup"
import Seo from "../components/seo"
import BlogEntry from "../components/blogEntry"

import { Dialog, Transition } from "@headlessui/react"

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share"

import {
  MailIcon,
  LinkIcon,
  CheckIcon,
  XIcon,
  ClipboardIcon,
} from "@heroicons/react/solid"

const Content = {
  ctaHeader: "Learn how you can bring 24/7 math tutoring to your school",
  ctaLink: Routes.schools,
  ctaText: "Schedule demo",

  // Closing CTA
  closingCTA: "Learn how you can bring 24/7 math tutoring to your school",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule demo",
    },
  ],
}

function BlogSection(props: { posts: Array<BlogEntry> }) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/blog-sections#component-720cf60b960fecb99c45f462f24db2d9
  return (
    <div className="max-w-7xl mx-auto relative pt-4 pb-20 px-4 sm:px-6 lg:px-8">
      <div className="mt-12 max-w-xl mx-auto grid gap-5 lg:grid-cols-2 justify-center">
        {props.posts.map((post: BlogEntry) => (
          <BlogEntry key={post.frontmatter.title} post={post} />
        ))}
      </div>
    </div>
  )
}

type CTAPopupProps = {
  show: boolean
  onDismiss: () => void
}
function CTAPopup(props: CTAPopupProps) {
  return (
    <Popup show={props.show} onDismiss={props.onDismiss}>
      <h2 className="text-3xl font-extrabold tracking-tight text-center text-white">
        {Content.ctaHeader}
      </h2>
      <div className="text-center mt-2">
        <PrimaryButton text={Content.ctaText} href={Content.ctaLink} />
      </div>
    </Popup>
  )
}

type SharingOptionsProps = {
  url: string
  title: string
  description: string
}
function SharingOptions(props: SharingOptionsProps) {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <div className="my-4 flex items-top space-x-2 text-gray-700">
        <div>Share:</div>
        <div title="Copy link">
          <button onClick={() => setOpen(true)}>
            <LinkIcon className="w-5 h-5" fill="currentColor" />
          </button>
        </div>
        <div title="Email">
          <EmailShareButton
            url={props.url}
            subject={props.title}
            body={props.description}
          >
            <MailIcon className="w-5 h-5" fill="currentColor" />
          </EmailShareButton>
        </div>
        <div title="Share on Facebook">
          <FacebookShareButton url={props.url} quote={props.description}>
            <svg
              className="w-5 h-5"
              viewBox="0 0 60.734 60.733"
              fill="currentColor"
            >
              <path
                d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
                  v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
                  v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"
              />
            </svg>
          </FacebookShareButton>
        </div>
        <div title="Share on LinkedIn">
          <LinkedinShareButton
            url={props.url}
            title={props.title}
            summary={props.description}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                clipRule="evenodd"
              />
            </svg>
          </LinkedinShareButton>
        </div>
        <div title="Share on Reddit">
          <RedditShareButton url={props.url} title={props.title}>
            <svg className="w-5 h-5" viewBox="0 0 20 20">
              <g>
                <circle fill="currentColor" cx="10" cy="10" r="10" />
                <path
                  fill="#FFFBF5"
                  d="M16.67,10A1.46,1.46,0,0,0,14.2,9a7.12,7.12,0,0,0-3.85-1.23L11,4.65,13.14,5.1a1,1,0,1,0,.13-0.61L10.82,4a0.31,0.31,0,0,0-.37.24L9.71,7.71a7.14,7.14,0,0,0-3.9,1.23A1.46,1.46,0,1,0,4.2,11.33a2.87,2.87,0,0,0,0,.44c0,2.24,2.61,4.06,5.83,4.06s5.83-1.82,5.83-4.06a2.87,2.87,0,0,0,0-.44A1.46,1.46,0,0,0,16.67,10Zm-10,1a1,1,0,1,1,1,1A1,1,0,0,1,6.67,11Zm5.81,2.75a3.84,3.84,0,0,1-2.47.77,3.84,3.84,0,0,1-2.47-.77,0.27,0.27,0,0,1,.38-0.38A3.27,3.27,0,0,0,10,14a3.28,3.28,0,0,0,2.09-.61A0.27,0.27,0,1,1,12.48,13.79Zm-0.18-1.71a1,1,0,1,1,1-1A1,1,0,0,1,12.29,12.08Z"
                />
              </g>
            </svg>
          </RedditShareButton>
        </div>
        <div title="Share on Twitter">
          <TwitterShareButton url={props.url} title={props.title}>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </TwitterShareButton>
        </div>
      </div>
      <CopyLinkModal
        open={open}
        url={props.url}
        onDismiss={() => setOpen(false)}
      />
    </div>
  )
}

type CopyLinkModalProps = {
  open: boolean
  url: string
  onDismiss: () => void
}
function CopyLinkModal(props: CopyLinkModalProps) {
  const [copied, setCopied] = useState(false)

  function onCopyLink() {
    navigator.clipboard.writeText(props.url)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.onDismiss}
      >
        <div className="flex items-center justify-center min-h-screen text-center sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="flex justify-end -mr-4 md:-m-4">
                  <button onClick={props.onDismiss}>
                    <XIcon className="h-6 w-6 text-gray-400" />
                  </button>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-display text-gray-900"
                  >
                    {copied ? "Link Copied!" : "Copy Link"}
                  </Dialog.Title>
                  <div className="my-4 flex space-x-2">
                    <input
                      value={props.url}
                      readonly
                      className="w-full bg-gray-100 px-2"
                    />
                    <button onClick={onCopyLink}>
                      {copied ? (
                        <CheckIcon className="h-6 w-6 text-gray-500" />
                      ) : (
                        <ClipboardIcon className="h-6 w-6 text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const BlogPostTemplate = ({ pageContext, data, location }) => {
  const [show, setShow] = useState(false)

  const post = data.markdownRemark
  const siteTitle =
    data.site.siteMetadata?.title || `${post.frontmatter.date} blog post`
  const { previous, next } = pageContext
  var related = []
  if (previous) {
    related.push(previous)
  }
  if (next) {
    related.push(next)
  }
  const image = getImage(post.frontmatter.coverImage)
  const imageAltText =
    post.frontmatter.coverImageAltText || post.frontmatter.title
  const categoryLabel = post.frontmatter.categories[0]?.replace(/-/g, " ") ?? ""
  const shareURL = `https://yup.com${Routes.blog}${post.frontmatter.slug}`
  const description = post.frontmatter.description || post.excerpt

  function onScroll() {
    setShow(true)
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      hidePopup={true}
      onScroll={onScroll}
    >
      <Seo
        title={post.frontmatter.title}
        description={description}
        image={post.frontmatter.coverImage.publicURL}
        route={`${Routes.blog}${post.frontmatter.slug}`}
      />
      <div className="relative overflow-hidden">
        <div className="relative">
          <div className="absolute inset-0">
            <GatsbyImage
              className="w-full h-full object-cover"
              image={image}
              alt={imageAltText}
            />
            <div className="absolute inset-0 bg-gray-600 mix-blend-multiply" />
          </div>
          <div className="relative max-w-2xl mx-auto py-24 px-4 sm:pt-60 sm:pb-8 sm:px-6 lg:px-8">
            <h1>
              <span className="block text-lg text-center text-primary font-bold tracking-wide uppercase">
                {categoryLabel}
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-black tracking-tight text-white sm:text-4xl">
                {post.frontmatter.title}
              </span>
            </h1>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8 max-w-2xl mx-auto ">
          <div className="mt-6 text-gray-500 mx-auto">
            <p className="mb-4">{post.frontmatter.date}</p>
            <article
              className="blog-post prose"
              itemScope
              itemType="http://schema.org/Article"
            >
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <SharingOptions
                url={shareURL}
                title={post.frontmatter.title}
                description={description}
              />
              <hr className="my-4" />
            </article>
          </div>
        </div>
      </div>
      <BlogSection posts={related} />
      <ClosingCTA
        title={Content.closingCTA}
        actions={Content.closingCTAActions}
      />
      <CTAPopup show={show} onDismiss={() => setShow(false)} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        categories
        slug
        coverImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  }
`
